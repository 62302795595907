<template>
  <div>
    <!--Banner-->
    <section id="banner2">
      <b-carousel
        id="homepage-carousel"
        v-model="carouselSlide"
        :interval="5000"
        controls
        img-width="1024"
        img-height="768"
      >
        <b-carousel-slide
          caption-html="<h1 class='white-text'>Quality Pre-owned Car Sales</h1>"
          :img-src="
            require('@/assets/images/homepage/vitor-pinto-3t_L1Carpx8-unsplash.jpg')
          "
        >
          <h5 class="smaller-caption-text">
            We stock a selection of signature vehicles to suit every budget
          </h5></b-carousel-slide
        >
        <b-carousel-slide
          caption-html="<h1 class='white-text'>Find Your Ideal Car</h1>"
          :img-src="
            require('@/assets/images/homepage/erik-mclean-sirUHp_wdgM-unsplash.jpg')
          "
        >
          <h5 class="smaller-caption-text">
            We can find your ideal car for you using our database of over 12,000
            cars
          </h5></b-carousel-slide
        >
        <b-carousel-slide
          caption-html="<h1 class='white-text'>Wide Selection of Vehicles</h1>"
          :img-src="
            require('@/assets/images/homepage/midland-auto-sales-hamilton-leicester-1920x830.jpg')
          "
        >
          <h5 class="smaller-caption-text">
            From big SUVs to small city cars, rest-assured we have the right car
            for you
          </h5></b-carousel-slide
        >
      </b-carousel>
    </section>
    <!--/Banner-->

    <!-- Filter-Form -->
    <section id="filter_form2">
      <div class="container">
        <div class="main_bg white-text">
          <h3>Find Your Ideal Car</h3>

          <form action="#" method="get">
            <div class="row">
              <div class="form-group col-md-4 col-sm-6">
                <div class="select">
                  <select v-model="selectItemsFilterMake" class="form-control">
                    <option :value="null">Any Make</option>
                    <option
                      v-for="(make, index) in getItemsFilterMakes"
                      :key="index"
                      :value="make"
                      >{{ make }}</option
                    >
                  </select>
                </div>
              </div>
              <div class="form-group col-md-4 col-sm-6">
                <div class="select">
                  <select v-model="selectItemsFilterModel" class="form-control">
                    <option :value="null">Any Model</option>
                    <option
                      v-for="(model, index) in getItemsFilterModels"
                      :key="index"
                      :value="model"
                      >{{ model }}</option
                    >
                  </select>
                </div>
              </div>
              <div class="form-group col-md-4 col-sm-6">
                <div class="select">
                  <select v-model="selectItemsFilterYear" class="form-control">
                    <option :value="null">Any Year</option>
                    <option
                      v-for="(model, index) in getItemsFilterYears"
                      :key="index"
                      :value="model"
                      >{{ model }}</option
                    >
                  </select>
                </div>
              </div>
              <div class="form-group col-md-3 col-sm-6">
                <div class="select">
                  <select v-model="selectItemsFilterFuel" class="form-control">
                    <option :value="null">Any Fuel Type</option>
                    <option
                      v-for="(model, index) in getItemsFilterFuels"
                      :key="index"
                      :value="model"
                      >{{ model }}</option
                    >
                  </select>
                </div>
              </div>

              <div class="form-group col-md-3 col-sm-6">
                <select v-model="selectItemsFilterMileage" class="form-control">
                  <option :value="null">Any Mileage</option>
                  <option :value="5000">Up to 5k miles</option>
                  <option :value="10000">Up to 10k miles</option>
                  <option :value="20000">Up to 20k miles</option>
                  <option :value="30000">Up to 30k miles</option>
                  <option :value="40000">Up to 40k miles</option>
                  <option :value="50000">Up to 50k miles</option>
                  <option :value="75000">Up to 75k miles</option>
                  <option :value="100000">Up to 100k miles</option>
                  <option :value="125000">Up to 125k miles</option>
                  <option :value="150000">Up to 150k miles</option>
                  <option :value="999999">Over 150k miles</option>
                </select>
              </div>

              <div class="form-group col-md-3 col-sm-6">
                <div class="select">
                  <select v-model="selectItemsFilterPrice" class="form-control">
                    <option :value="null">Any Price</option>
                    <option :value="1000">Up to £1,000</option>
                    <option :value="2000">Up to £2,500</option>
                    <option :value="5000">Up to £5,000</option>
                    <option :value="7500">Up to £7,500</option>
                    <option :value="10000">Up to £10,000</option>
                    <option :value="15000">Up to £15,000</option>
                    <option :value="999999">Over £15,000</option>
                  </select>
                </div>
              </div>
              <div class="form-group col-md-3 col-sm-6">
                <form @submit.prevent="searchVehicles">
                  <button
                    type="submit"
                    class="btn btn-block"
                    style="color: #fff;"
                  >
                    <i class="fa fa-search" aria-hidden="true"></i> Search Car
                  </button>
                </form>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
    <!-- /Filter-Form -->

    <!--About-us-->
    <section id="about_us" class="section-padding">
      <div class="container">
        <div class="section-header text-center">
          <h2>Welcome to Midland Auto Sales</h2>
          <p>
            We have a selection of quality, pre-owned vehicles available at very
            competitive prices.
          </p>
          <p>
            All vehicles include a 12 months nationwide parts and garage
            warranty, for peace of mind.
          </p>
          <p>
            Our quality assurance guarantees that all vehicles are inspected to
            ensure you are safe on the road. Our vehicles are also HPi checked
            with mileages verified.
          </p>
          <p>
            We offer all dealer facilities such as competitive finance packages
            and we also accept payments by debit/credit cards or bank transfer.
          </p>
          <p>
            To arrange viewings and test drives, please call our sales team on
            07443 801 790. We are based in Leicester, LE5.
          </p>
        </div>
        <div class="row">
          <div class="col-md-3 col-sm-6">
            <div class="about_info">
              <div class="icon_box">
                <i class="fa fa-money" aria-hidden="true"></i>
              </div>
              <h5>Best Price</h5>
              <p>We will aim to offer you very competitive prices</p>
            </div>
          </div>

          <div class="col-md-3 col-sm-6">
            <div class="about_info">
              <div class="icon_box">
                <i class="fa fa-thumbs-o-up" aria-hidden="true"></i>
              </div>
              <h5>Quality Service</h5>
              <p>A happy customer is our ultimate goal</p>
            </div>
          </div>

          <div class="col-md-3 col-sm-6">
            <div class="about_info">
              <div class="icon_box">
                <i class="fa fa-history" aria-hidden="true"></i>
              </div>
              <h5>Always Available</h5>
              <p>Our team will be happy to help at all times</p>
            </div>
          </div>

          <div class="col-md-3 col-sm-6">
            <div class="about_info">
              <div class="icon_box">
                <i class="fa fa-users" aria-hidden="true"></i>
              </div>
              <h5>Happy Customers</h5>
              <p>Our business is built upon repeat custom</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--/About-us-->

    <!--Fan-Fact-->
    <section id="fun-facts" class="dark-bg vc_row">
      <div class=" col-md-6 vc_col section-padding">
        <div class="fact_m white-text">
          <h2>About Midland Auto Sales</h2>
          <p>
            For great savings on quality pre-owned cars, please view our stock
            list page to find vehicles that suit your budget and lifestyle.
          </p>
          <p>
            Midland Auto Sales are based in the Leicestershire area and are
            proud to offer you first class customer service and very competitive
            pricing.
          </p>
          <p>
            Our stock list is updated regularly, however, if you do not find
            what you are looking for, please call us as we can source the right
            car for you from our database of over 12,000 cars.
          </p>
          <p>
            We can offer advice on vehicles and vehicle maintenance and our
            friendly team will guarantee that you receive the advice and support
            you need.
          </p>
          <p>
            It would be our pleasure to do business with you and we strive in
            providing you the best customer service!
          </p>
          <ul>
            <li>
              <i class="fa fa-calendar" aria-hidden="true"></i>
              <h2>20+</h2>
              <p>Years In Business</p>
            </li>

            <li>
              <i class="fa fa-car" aria-hidden="true"></i>
              <h2>12,000+</h2>
              <p>Database of Pre-Owned Cars</p>
            </li>

            <li>
              <i class="fa fa-clock-o" aria-hidden="true"></i>
              <h2>24 / 7</h2>
              <p>Available long hours</p>
            </li>

            <li>
              <i class="fa fa-user-circle" aria-hidden="true"></i>
              <h2>1,000+</h2>
              <p>Satisfied Customers</p>
            </li>
          </ul>
        </div>
      </div>
      <div class=" col-md-6 vc_col section-padding">
        <div class="about_bg"></div>
      </div>
    </section>
    <!--/Fan-fact-->

    <!--Featured Car-->
    <section class="section-padding">
      <div class="container">
        <div class="section-header text-center">
          <h2>Featured Cars</h2>
          <p>
            Please feel free to browse our continuously updated stock list. We
            have a selection of cars of the highest quality with full
            nationwide, 5 star warranty cover.
          </p>
          <p>
            Part Exchanges are welcome so let us value your car and offer you
            the best price.
          </p>
          <p>
            Viewing by appointment - We will do our best to book a convenient
            time to suit you.
          </p>
        </div>
        <FeaturedCars />
      </div>
    </section>
    <!-- /Featured Car-->

    <!-- Services -->
    <section id="our_services" class="dark-bg vc_row">
      <div class="col-md-6 vc_col section-padding">
        <div class="facts_section_bg"></div>
      </div>

      <div class=" col-md-6 vc_col section-padding">
        <div class="our_services white-text">
          <h2>Our Services</h2>
          <p>
            We are an established business, with over 20 years experience in the
            motor industry.
          </p>
          <p>
            Our ethos is
            <span class="bold-text">Excellence Remembered - Trust Inspired</span
            >. Customer service is our main priority so we do our best to meet
            the customers' needs and help is always on hand.
          </p>
          <p>
            We have a number of satisfied customers including local businesses
            as well as the general public
          </p>
          <p>
            If you are specific about a particular vehicle, we will do our best
            to source and deliver it to you at trade prices.
          </p>
          <p>
            All our vehicles are of excellent standard; most with a mechanical
            report, are HPi checked, have warranted mileages and come with a
            superb service record.
          </p>
          <p>
            We offer a 12 months nationwide warranty with all our vehicles.
            (Terms and Conditions apply)
          </p>

          <!--Services-info-->
          <div class="services_info">
            <div class="icon_box">
              <i class="fa fa-calendar-check-o" aria-hidden="true"></i>
            </div>
            <h4><a href="#">Warranty</a></h4>
            <p>
              We offer a 12 months nationwide warranty with all our vehicles.
              (Terms and Conditions apply)
            </p>
          </div>

          <!--Services-info-->
          <div class="services_info">
            <div class="icon_box">
              <i class="fa fa-exchange" aria-hidden="true"></i>
            </div>
            <h4><a href="#">Part Exchanges</a></h4>
            <p>Part exchanges are considered and we also buy cars for cash</p>
          </div>
        </div>
      </div>
    </section>
    <!-- /Services -->

    <!--Testimonial -->
    <!-- <section id="testimonial" class="section-padding">
      <div class="container div_zindex">
        <div class="section-header text-center">
          <h2>Our Testimonial</h2>
          <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. </p>
        </div>
        <div class="row">
          <div id="testimonial-slider-2">
              <div class="testimonial_wrap">
                  <div class="testimonial-img">
                      <img src="assets/images/300x300.jpg" alt="image">
                  </div>
                  <div class="testimonial-heading">
                      <h5>Donald Brooks</h5>
                      <span class="client-designation">CEO of xzy company</span>
                  </div>
                  <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et
                  quas molestias excepturi sint occaecati cupiditate non provident, similique sunt .</p>
              </div>

              <div class="testimonial_wrap">
                  <div class="testimonial-img">
                      <img src="assets/images/300x300.jpg" alt="image">
                  </div>
                  <div class="testimonial-heading">
                      <h5>Enzo Giovanotelli </h5>
                      <span class="client-designation">CEO of xzy company</span>
                  </div>
                  <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et
                  quas molestias excepturi sint occaecati cupiditate non provident, similique sunt .</p>
              </div>

              <div class="testimonial_wrap">
                  <div class="testimonial-img">
                      <img src="assets/images/300x300.jpg" alt="image">
                  </div>
                  <div class="testimonial-heading">
                      <h5>Donald Brooks</h5>
                      <span class="client-designation">CEO of xzy company</span>
                  </div>
                  <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et
                  quas molestias excepturi sint occaecati cupiditate non provident, similique sunt .</p>
              </div>

              <div class="testimonial_wrap">
                  <div class="testimonial-img">
                      <img src="assets/images/300x300.jpg" alt="image">
                  </div>
                  <div class="testimonial-heading">
                      <h5>Enzo Giovanotelli </h5>
                      <span class="client-designation">CEO of xzy company</span>
                  </div>
                  <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et
                  quas molestias excepturi sint occaecati cupiditate non provident, similique sunt .</p>
              </div>

              <div class="testimonial_wrap">
                  <div class="testimonial-img">
                      <img src="assets/images/300x300.jpg" alt="image">
                  </div>
                  <div class="testimonial-heading">
                      <h5>Enzo Giovanotelli </h5>
                      <span class="client-designation">CEO of xzy company</span>
                  </div>
                  <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et
                  quas molestias excepturi sint occaecati cupiditate non provident, similique sunt .</p>
              </div>
          </div>
        </div>
      </div>

    </section> -->
    <!-- /Testimonial-->

    <!-- Help-Number-->
    <section id="help" class="section-padding">
      <div class="container">
        <div class="div_zindex white-text text-center">
          <h2>
            Have Any Questions?<br />
            07443 801 790
          </h2>
        </div>
      </div>

      <!-- Dark-overlay-->
      <div class="dark-overlay"></div>
    </section>
    <!-- /Help-Number-->

    <!--Brands-->
    <!-- <section class="brand-section gray-bg">
      <div class="container">
        <div class="brand-hadding">
          <h5>Popular Brands</h5>
        </div>
        <div class="brand-logo-list">
          <div id="popular_brands">
            <div><a href="#"><img src="../assets/images/homepage/logo-bar-1-100x60.png" class="img-fluid" alt="image"></a></div>
            <div><a href="#"><img src="../assets/images/homepage/logo-bar-2-100x60.png" class="img-fluid" alt="image"></a></div>
            <div><a href="#"><img src="../assets/images/homepage/logo-bar-3-100x60.png" class="img-fluid" alt="image"></a></div>
            <div><a href="#"><img src="../assets/images/homepage/logo-bar-4-100x60.png" class="img-fluid" alt="image"></a></div>
            <div><a href="#"><img src="../assets/images/homepage/logo-bar-5-100x60.png" class="img-fluid" alt="image"></a></div>
            <div><a href="#"><img src="../assets/images/homepage/logo-bar-6-100x60.png" class="img-fluid" alt="image"></a></div>
            <div><a href="#"><img src="../assets/images/homepage/logo-bar-7-100x60.png" class="img-fluid" alt="image"></a></div>
          </div>
        </div>
      </div>
    </section> -->
    <!-- /Brands-->
  </div>
</template>

<script>
  import moment from 'moment'
  import { mapState, mapGetters, mapActions } from 'vuex'

  export default {
    name: 'Home',
    components: {
      FeaturedCars: () => import('../components/FeaturedCars'),
    },
    data() {
      return {
        carouselSlide: 0,
        slide1html:
          '<span>We stock a selection of signature vehicles to suit every budget</span>',
        slide2html:
          '<span>We can find your ideal car for you using our database of over 12,000 cars</span>',
        slide3html:
          '<span>From big SUVs to small city cars, rest-assured we have the right car for you</span>',
      }
    },
    computed: {
      ...mapState('stock', [
        'itemsPerPage',
        'itemsSortBy',
        'itemsCurrentPage',
        'itemsFilterMake',
        'itemsFilterModel',
        'itemsFilterYear',
        'itemsFilterFuel',
        'itemsFilterMileage',
        'itemsFilterPrice',
      ]),
      ...mapGetters('stock', ['getActiveVehicles']),

      vehiclesFilteredSorted() {
        let vehicles = this.getActiveVehicles

        // Filter
        if (this.selectItemsFilterMake) {
          vehicles = vehicles.filter(
            (v) => v.vehicle.make === this.selectItemsFilterMake
          )
        }
        if (this.selectItemsFilterModel) {
          vehicles = vehicles.filter(
            (v) => v.vehicle.model === this.selectItemsFilterModel
          )
        }
        if (this.selectItemsFilterYear) {
          vehicles = vehicles.filter(
            (v) =>
              moment(v.vehicle.registrationDate).format('YYYY') ===
              this.selectItemsFilterYear
          )
        }
        if (this.selectItemsFilterFuel) {
          vehicles = vehicles.filter(
            (v) => v.vehicle.fuelType === this.selectItemsFilterFuel
          )
        }
        if (this.selectItemsFilterMileage) {
          vehicles = vehicles.filter(
            (v) => v.vehicle.mileage <= this.selectItemsFilterMileage
          )
        }
        if (this.selectItemsFilterPrice) {
          vehicles = vehicles.filter(
            (v) => v.advert.price <= this.selectItemsFilterPrice
          )
        }

        return vehicles
      },

      selectItemsFilterMake: {
        get() {
          return this.itemsFilterMake
        },
        set(val) {
          this.setItemsFilterMake(val)
        },
      },
      selectItemsFilterModel: {
        get() {
          return this.itemsFilterModel
        },
        set(val) {
          this.setItemsFilterModel(val)
        },
      },
      selectItemsFilterYear: {
        get() {
          return this.itemsFilterYear
        },
        set(val) {
          this.setItemsFilterYear(val)
        },
      },
      selectItemsFilterFuel: {
        get() {
          return this.itemsFilterFuel
        },
        set(val) {
          this.setItemsFilterFuel(val)
        },
      },
      selectItemsFilterMileage: {
        get() {
          return this.itemsFilterMileage
        },
        set(val) {
          this.setItemsFilterMileage(val)
        },
      },
      selectItemsFilterPrice: {
        get() {
          return this.itemsFilterPrice
        },
        set(val) {
          this.setItemsFilterPrice(val)
        },
      },

      getItemsFilterMakes() {
        return Array.from(
          new Set(this.vehiclesFilteredSorted.map((item) => item.vehicle.make))
        ).sort()
      },
      getItemsFilterModels() {
        return Array.from(
          new Set(this.vehiclesFilteredSorted.map((item) => item.vehicle.model))
        ).sort()
      },
      getItemsFilterYears() {
        return Array.from(
          new Set(
            this.vehiclesFilteredSorted.map((item) =>
              moment(item.vehicle.registrationDate).format('YYYY')
            )
          )
        ).sort()
      },
      getItemsFilterFuels() {
        return Array.from(
          new Set(
            this.vehiclesFilteredSorted.map((item) => item.vehicle.fuelType)
          )
        ).sort()
      },
    },
    mounted() {
      this.dbGetAllVehicles()
      this.resetSearch()
    },
    beforeDestroy() {
      this.dbUnsubscribeVehicles()
    },
    methods: {
      ...mapActions('stock', [
        'dbGetAllVehicles',
        'dbUnsubscribeVehicles',
        'setItemsCurrentPage',
        'setItemsFilterMake',
        'setItemsFilterModel',
        'setItemsFilterYear',
        'setItemsFilterFuel',
        'setItemsFilterMileage',
        'setItemsFilterPrice',
      ]),

      searchVehicles() {
        this.setItemsCurrentPage(1)
        this.$router.push({ path: '/pre-owned' })
      },
      resetSearch() {
        this.selectItemsFilterMake = null
        this.selectItemsFilterModel = null
        this.selectItemsFilterYear = null
        this.selectItemsFilterFuel = null
        this.selectItemsFilterMileage = null
        this.selectItemsFilterPrice = null
      },
    },
  }
</script>
<style scoped>
  .carousel-caption {
    color: green;
    font-size: 22px;
    border: 5px solid white;
  }
  .bold-text {
    font-weight: bold;
  }
  #banner2 .carousel-item:after {
    background: rgba(0, 0, 0, 0.3) none repeat scroll 0 0;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .smaller-caption-text {
    color: white;
  }

  @media only screen and (max-width: 600px) {
    .smaller-caption-text {
      color: white;
      font-size: 70%;
    }
  }
</style>
